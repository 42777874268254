@import "icomoon-variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?2v8wmr');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?2v8wmr#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?2v8wmr') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?2v8wmr') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?2v8wmr##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-tents {
  &:before {
    content: $icon-tents; 
  }
}
.icon-favicon {
  &:before {
    content: $icon-favicon; 
  }
}
.icon-triangle {
  &:before {
    content: $icon-triangle; 
  }
}
.icon-avatar {
  &:before {
    content: $icon-avatar; 
  }
}
.icon-bndes {
  &:before {
    content: $icon-bndes; 
  }
}
.icon-brazil {
  &:before {
    content: $icon-brazil; 
  }
}
.icon-budgeting {
  &:before {
    content: $icon-budgeting; 
  }
}
.icon-customer-support {
  &:before {
    content: $icon-customer-support; 
  }
}
.icon-discount {
  &:before {
    content: $icon-discount; 
  }
}
.icon-quality {
  &:before {
    content: $icon-quality; 
  }
}
.icon-sun {
  &:before {
    content: $icon-sun; 
  }
}
.icon-truck {
  &:before {
    content: $icon-truck; 
  }
}
.icon-welder {
  &:before {
    content: $icon-welder; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-zoom {
  &:before {
    content: $icon-zoom; 
  }
}
.icon-route {
  &:before {
    content: $icon-route; 
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope; 
  }
}
.icon-phone-call {
  &:before {
    content: $icon-phone-call; 
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-tdh-logo {
  &:before {
    content: $icon-tdh-logo; 
  }
}

