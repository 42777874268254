.head-sec {
    position: relative;
    .head-sec_title {
        font-size: 70px;
        color: $brand-color-secondary;
        line-height: em(75px,80px);
    }
    .head-sec_subtitle {
        font-family: $text-font;
        font-size: 25px;
        color: $text-color-light;
        margin: 25px 0 0;
    }

    @include breakpoint-max(small) {
        .head-sec_title {
            font-size: 32px;
        }
        .head-sec_subtitle {
            font-size: 18px;
        }
    }
}