$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

$icon-arrow-up: "\e900";
$icon-arrow-down: "\e901";
$icon-arrow-left: "\e902";
$icon-arrow-right: "\e903";
$icon-tents: "\e904";
$icon-favicon: "\e918";
$icon-triangle: "\e905";
$icon-avatar: "\e906";
$icon-bndes: "\e907";
$icon-brazil: "\e908";
$icon-budgeting: "\e909";
$icon-customer-support: "\e90a";
$icon-discount: "\e90b";
$icon-quality: "\e90c";
$icon-sun: "\e90d";
$icon-truck: "\e90e";
$icon-welder: "\e90f";
$icon-play: "\e910";
$icon-zoom: "\e911";
$icon-route: "\e912";
$icon-envelope: "\e913";
$icon-phone-call: "\e914";
$icon-whatsapp: "\e915";
$icon-instagram: "\e916";
$icon-tdh-logo: "\e917";

