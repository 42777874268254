@charset "UTF-8";
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?2v8wmr");
  src: url("../fonts/icomoon.eot?2v8wmr#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?2v8wmr") format("truetype"), url("../fonts/icomoon.woff?2v8wmr") format("woff"), url("../fonts/icomoon.svg?2v8wmr#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-up:before {
  content: ""; }

.icon-arrow-down:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-tents:before {
  content: ""; }

.icon-favicon:before {
  content: ""; }

.icon-triangle:before {
  content: ""; }

.icon-avatar:before {
  content: ""; }

.icon-bndes:before {
  content: ""; }

.icon-brazil:before {
  content: ""; }

.icon-budgeting:before {
  content: ""; }

.icon-customer-support:before {
  content: ""; }

.icon-discount:before {
  content: ""; }

.icon-quality:before {
  content: ""; }

.icon-sun:before {
  content: ""; }

.icon-truck:before {
  content: ""; }

.icon-welder:before {
  content: ""; }

.icon-play:before {
  content: ""; }

.icon-zoom:before {
  content: ""; }

.icon-route:before {
  content: ""; }

.icon-envelope:before {
  content: ""; }

.icon-phone-call:before {
  content: ""; }

.icon-whatsapp:before {
  content: ""; }

.icon-instagram:before {
  content: ""; }

.icon-tdh-logo:before {
  content: ""; }

body {
  font-family: "Lexend Deca", sans-serif; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Baloo 2", sans-serif;
  text-transform: uppercase; }

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="file"],
input[type="search"],
select,
textarea {
  color: #98A1AB;
  transition: all 0.3s ease; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="tel"]::-webkit-input-placeholder,
  input[type="file"]::-webkit-input-placeholder,
  input[type="search"]::-webkit-input-placeholder,
  select::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #98a1ab;
    opacity: 1; }
  input[type="text"]:-moz-placeholder,
  input[type="number"]:-moz-placeholder,
  input[type="password"]:-moz-placeholder,
  input[type="email"]:-moz-placeholder,
  input[type="tel"]:-moz-placeholder,
  input[type="file"]:-moz-placeholder,
  input[type="search"]:-moz-placeholder,
  select:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #98a1ab;
    opacity: 1; }
  input[type="text"]::-moz-placeholder,
  input[type="number"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder,
  input[type="tel"]::-moz-placeholder,
  input[type="file"]::-moz-placeholder,
  input[type="search"]::-moz-placeholder,
  select::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #98a1ab;
    opacity: 1; }
  input[type="text"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="tel"]:-ms-input-placeholder,
  input[type="file"]:-ms-input-placeholder,
  input[type="search"]:-ms-input-placeholder,
  select:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #98a1ab;
    opacity: 1; }
  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus,
  input[type="file"]:focus,
  input[type="search"]:focus,
  select:focus,
  textarea:focus {
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.05); }

@media (hover: hover) {
  a {
    transition: all 0.3s ease; }
    a:hover {
      color: #F38120; } }

form .field {
  margin-bottom: 20px; }
  form .field label {
    display: block;
    position: relative;
    font-size: 16px; }
    form .field label span.label {
      display: block;
      margin-bottom: 5px; }
      form .field label span.label strong {
        color: #cc0000; }
    form .field label.errorField p {
      position: absolute;
      top: calc(100% + 2px);
      right: 0;
      color: #cc0000;
      font-size: 16px; }
  form .field .form-control {
    transition: all 0.3s ease; }
    form .field .form-control:focus {
      border-color: #cccccc; }

.form-alert {
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px;
  text-align: center;
  width: 100%; }
  .form-alert svg {
    height: 50px;
    margin: 0 0 10px;
    width: 50px; }
    .form-alert svg path {
      fill: #EA1566; }
  .form-alert h2 {
    font-size: 32px;
    font-weight: 400;
    line-height: 1.3em;
    margin: 0; }
  .form-alert span {
    display: block;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.4em; }
  .form-alert.success {
    background-color: rgba(39, 174, 96, 0.1);
    border: solid 1px rgba(39, 174, 96, 0.12);
    color: #27ae60; }
    .form-alert.success svg path {
      fill: #075E54; }
    .form-alert.success h2 {
      color: #075E54; }
  .form-alert.error {
    background-color: rgba(204, 0, 0, 0.1);
    border: solid 1px rgba(204, 0, 0, 0.12);
    color: #cc0000; }
    .form-alert.error svg path {
      fill: #cc0000; }
    .form-alert.error h2 {
      color: #cc0000; }

.logo-tdh {
  display: inline-block;
  opacity: 0.6; }
  @media (hover: hover) {
    .logo-tdh {
      transition: all 0.3s ease; }
      .logo-tdh:hover {
        opacity: 1; } }

.logo-client {
  display: table; }
  @media (hover: hover) {
    .logo-client {
      transition: all 0.3s ease; }
      .logo-client:hover {
        opacity: 0.7; } }
  @media (max-width: 1199px) {
    .logo-client {
      margin: 0 auto 15px; } }

.back-top {
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  color: #3F56A4;
  padding-right: 25px;
  margin: 10px 0 0; }
  .back-top::after {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    content: "";
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 13px;
    color: #3F56A4; }
  @media (hover: hover) {
    .back-top {
      transition: all 0.3s ease; }
      .back-top::before {
        position: absolute;
        content: attr(data-hover);
        opacity: 0;
        text-shadow: 0 0 1px #ffff09;
        transform: scale(1.1) translateX(10px) translateY(-10px) rotate(4deg);
        pointer-events: none;
        transition: transform 0.3s, opacity 0.3s; }
      .back-top::after {
        transition: all 0.3s ease; }
      .back-top:hover {
        color: #F38120; }
        .back-top:hover::before {
          transform: scale(1) translateX(0px) translateY(0px) rotate(0deg);
          opacity: 1; }
        .back-top:hover::after {
          color: #F38120; } }

/* ----------------------------------------------
 * Generated by Animista on 2021-3-13 14:59:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation flip-vertical-right
 * ----------------------------------------
 */
@-webkit-keyframes flip-vertical-right {
  0% {
    transform: rotateY(0); }
  100% {
    transform: rotateY(360deg); } }

@keyframes flip-vertical-right {
  0% {
    transform: rotateY(0); }
  100% {
    transform: rotateY(360deg); } }

/* ----------------------------------------------
* Generated by Animista on 2021-3-14 13:33:17
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */
/**
* ----------------------------------------
* animation rotate-hor-center
* ----------------------------------------
*/
@-webkit-keyframes rotate-hor-center {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0); }
  100% {
    -webkit-transform: rotateX(-360deg);
    transform: rotateX(-360deg); } }

@keyframes rotate-hor-center {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0); }
  100% {
    -webkit-transform: rotateX(-360deg);
    transform: rotateX(-360deg); } }

/* ----------------------------------------------
* Generated by Animista on 2021-3-15 10:43:9
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */
/**
* ----------------------------------------
* animation focus-in-expand
* ----------------------------------------
*/
@-webkit-keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

/* ----------------------------------------------
* Generated by Animista on 2021-3-15 10:47:18
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */
/**
* ----------------------------------------
* animation heartbeat
* ----------------------------------------
*/
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }

/* ----------------------------------------------
* Generated by Animista on 2021-3-15 10:48:37
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */
/**
* ----------------------------------------
* animation vibrate-2
* ----------------------------------------
*/
@-webkit-keyframes vibrate-2 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0); }
  20% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px); }
  40% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px); }
  60% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px); }
  80% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px); }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0); } }

@keyframes vibrate-2 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0); }
  20% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px); }
  40% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px); }
  60% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px); }
  80% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px); }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0); } }

.breadcrumbs {
  border-bottom: 1px solid #e5e5e5; }
  .breadcrumbs ol {
    position: relative;
    padding-left: 105px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start; }
    .breadcrumbs ol::before {
      position: absolute;
      top: calc(50% + 1px);
      left: 0;
      transform: translateY(-50%);
      content: attr(data-title);
      font-size: 10px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.3);
      text-transform: uppercase;
      letter-spacing: 2px; }
    .breadcrumbs ol li {
      position: relative;
      line-height: 0; }
      .breadcrumbs ol li + li {
        margin-left: 30px; }
        .breadcrumbs ol li + li::before {
          position: absolute;
          top: 50%;
          left: -20px;
          transform: translateY(-50%);
          content: "";
          font-family: 'icomoon' !important;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          font-size: 15px;
          color: #e5e5e5; }
      .breadcrumbs ol li:first-child {
        margin-left: 10px; }
      .breadcrumbs ol li a {
        display: inline-block;
        font-size: 10px;
        font-weight: 400;
        color: #98A1AB;
        line-height: 0;
        padding: 20px 0 15px; }
  @media (max-width: 767px) {
    .breadcrumbs {
      display: none; } }

.site-foot {
  padding: 80px 0 20px; }
  .site-foot .foot-logo,
  .site-foot .foot-copy,
  .site-foot .foot-social {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start; }
  .site-foot .foot-logo .logo-client {
    margin-left: 130px; }
  .site-foot .foot-copy {
    font-size: 18px; }
    .site-foot .foot-copy .foot-alert {
      max-width: 460px;
      margin-left: 35px; }
      .site-foot .foot-copy .foot-alert + .foot-cnpj {
        margin-left: 50px; }
    .site-foot .foot-copy .foot-cnpj {
      max-width: 340px; }
  .site-foot .social-media {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start; }
    .site-foot .social-media li + li {
      margin-left: 25px; }
    .site-foot .social-media li a {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      height: 55px;
      width: 55px;
      border-radius: 50%; }
      @media (hover: hover) {
        .site-foot .social-media li a:hover {
          animation: flip-vertical-right 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) both; } }
      .site-foot .social-media li a.facebook {
        background-color: #20B9D4; }
      .site-foot .social-media li a.instagram {
        background-color: #EA1566; }
      .site-foot .social-media li a.youtube {
        background-color: #F38120; }
  .site-foot .foot-nav {
    margin-left: 35px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start; }
    .site-foot .foot-nav li + li {
      margin-left: 35px; }
    .site-foot .foot-nav li a {
      display: block;
      font-size: 16px;
      font-weight: 400;
      color: #20B9D4;
      text-decoration: underline; }
  @media (max-width: 1600px) {
    .site-foot .foot-logo .logo-client {
      margin-left: 0; }
    .site-foot .foot-copy {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center; }
      .site-foot .foot-copy .foot-alert {
        margin-left: 0;
        max-width: max-content; }
        .site-foot .foot-copy .foot-alert + .foot-cnpj {
          margin-left: 0;
          margin-top: 20px; }
      .site-foot .foot-copy .foot-cnpj {
        max-width: max-content; }
    .site-foot .foot-nav {
      margin-left: 0; } }
  @media (max-width: 1080px) {
    .site-foot {
      padding-top: 40px; } }
  @media (max-width: 991px) {
    .site-foot {
      padding-top: 20px;
      text-align: center; }
      .site-foot .foot-logo,
      .site-foot .foot-copy,
      .site-foot .foot-social,
      .site-foot .foot-nav,
      .site-foot .grid-2,
      .site-foot .grid-10 {
        width: 100%; }
      .site-foot .foot-social,
      .site-foot .foot-nav {
        margin-top: 20px; }
      .site-foot .foot-copy {
        align-items: center; }
      .site-foot .foot-logo,
      .site-foot .foot-social,
      .site-foot .foot-nav {
        justify-content: center;
        text-align: center; }
      .site-foot .foot-logo .logo-client {
        margin-left: auto; } }

.site-head {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99; }
  .site-head .logo-client {
    position: relative;
    display: inline-block;
    background-color: #fff;
    padding: 35px 25px;
    border-radius: 0px 0px 10px 10px;
    margin-left: 130px; }
  @media (max-width: 1550px) {
    .site-head .logo-client {
      margin-left: 0; } }

.dataTables_scrollHead {
  display: none !important; }

.dataTables_sizing {
  height: auto !important;
  overflow: visible !important; }

.dataTables_info {
  display: none !important; }

table {
  width: 100%;
  border-collapse: collapse;
  border: 0;
  font-size: 16px;
  line-height: 16px;
  text-align: left; }
  table tr {
    text-align: center; }
    table tr td,
    table tr th {
      border-left: solid 2px #e5e5e5;
      padding: 13px 10px 11px !important;
      vertical-align: middle;
      width: auto !important;
      height: auto !important; }
      table tr td:first-child,
      table tr th:first-child {
        border-left: 0; }
  table thead tr {
    color: #fff;
    background-color: #3F56A4;
    height: auto !important; }
    table thead tr td,
    table thead tr th {
      border-color: #129051; }
  table tbody {
    overflow-x: scroll; }
    table tbody tr {
      border-bottom: 2px solid #e5e5e5; }

table tr:first-child td {
  border-top: 0; }

.box-time {
  margin-bottom: 30px; }
  .box-time .card-time {
    border: solid 1px #98A1AB;
    border-radius: 5px;
    padding: 25px 35px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto; }
    .box-time .card-time p {
      font-size: 20px;
      color: #98A1AB; }
      .box-time .card-time p span {
        display: inline-block;
        margin: 0 12px; }
        .box-time .card-time p span:first-child {
          margin-left: 0; }
        .box-time .card-time p span:last-child {
          margin-right: 0; }
    @media (hover: hover) {
      .box-time .card-time {
        transition: all 0.3s ease; }
        .box-time .card-time p {
          transition: all 0.3s ease; }
        .box-time .card-time:hover {
          background-color: #3F56A4;
          border-color: #3F56A4; }
          .box-time .card-time:hover p {
            color: #fff; } }
  @media (max-width: 1199px) {
    .box-time {
      width: 50%; } }
  @media (max-width: 1080px) {
    .box-time {
      width: 100%; }
      .box-time .card-time {
        border-color: #fff; }
        .box-time .card-time p {
          color: #fff; } }

.btn-all {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .btn-all.center {
    justify-content: center; }
  .btn-all.right {
    justify-content: flex-end; }

@media (hover: hover) {
  .btn {
    transition: all 0.3s ease; } }

.btn-block {
  width: 100%; }

.btn-whatsapp {
  color: #fff;
  background-color: #5EB946; }
  .btn-whatsapp img {
    margin-right: 13px; }
  @media (hover: hover) {
    .btn-whatsapp:hover {
      color: #fff;
      background-color: #EA1566; } }

.btn-brand {
  font-size: 14px;
  color: #fff;
  background-color: #EA1566; }
  @media (hover: hover) {
    .btn-brand:hover {
      background-color: #3F56A4; } }

.head-sec {
  position: relative; }
  .head-sec .head-sec_title {
    font-size: 70px;
    color: #F38120;
    line-height: 0.9375em; }
  .head-sec .head-sec_subtitle {
    font-family: "Lexend Deca", sans-serif;
    font-size: 25px;
    color: #98A1AB;
    margin: 25px 0 0; }
  @media (max-width: 575px) {
    .head-sec .head-sec_title {
      font-size: 32px; }
    .head-sec .head-sec_subtitle {
      font-size: 18px; } }

.box-cta {
  position: relative;
  padding: 220px 0 120px;
  z-index: 5;
  background-image: url(../images/backgrounds/slide-pc.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover; }
  .box-cta::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 200px;
    width: 100%;
    background-image: url(../images/backgrounds/waves.png);
    background-position: top center;
    background-repeat: no-repeat;
    z-index: -1; }
  .box-cta .box-cta_inner {
    max-width: 640px;
    width: 100%;
    margin-left: 130px; }
  .box-cta .box-cta_title {
    font-size: 80px;
    font-weight: 700;
    color: #EA1566;
    text-transform: uppercase;
    line-height: 0.9375em; }
  .box-cta img + .box-cta_title {
    margin-top: 30px; }
  .box-cta .box-cta_subtitle {
    font-family: "Lexend Deca", sans-serif;
    font-size: 30px;
    font-weight: 400;
    text-transform: none;
    letter-spacing: normal;
    color: #fff;
    margin-top: 25px; }
  .box-cta .box-cta_form form {
    background-color: #7CBCAD;
    max-width: 540px;
    border-radius: 20px;
    box-shadow: 0 20px 30px rgba(124, 188, 173, 0.05);
    padding: 70px 45px 60px;
    margin: 20px 0 0 30px; }
  .box-cta .box-cta_form .box-cta_form-title {
    position: relative;
    font-size: 40px;
    color: #fff;
    line-height: 1.125em;
    margin: 0 0 40px;
    z-index: 2; }
    .box-cta .box-cta_form .box-cta_form-title span {
      position: relative;
      display: inline-block; }
      .box-cta .box-cta_form .box-cta_form-title span::before {
        content: '';
        position: absolute;
        top: 0;
        left: -8px;
        height: calc(100% - 4px);
        width: calc(100% + 12px);
        border-radius: 5px;
        background-color: #3F56A4;
        z-index: -1; }
  .box-cta .box-cta_form .box-cta_alert {
    margin: 20px 0 0;
    text-align: center;
    color: #fff; }
  .box-cta .box-cta_form .box-downapp {
    display: none; }
  .box-cta .box-cta_mobile {
    display: none;
    position: relative;
    z-index: 5; }
    .box-cta .box-cta_mobile::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 255px;
      width: 100%;
      background-image: url(../images/backgrounds/waves-orange.png);
      background-position: left top;
      background-repeat: no-repeat;
      z-index: 1; }
  @media (max-width: 1550px) {
    .box-cta .box-cta_inner {
      margin-left: 0; } }
  @media (max-width: 1250px) {
    .box-cta .box-cta_inner {
      max-width: 470px; }
    .box-cta .box-cta_title {
      font-size: 60px; }
    .box-cta .box-cta_subtitle {
      font-size: 26px; } }
  @media (max-width: 1080px) {
    .box-cta {
      background-image: none;
      background-color: #f9ad1b;
      padding-top: 0;
      padding-bottom: 185px; }
      .box-cta::before {
        background-image: url(../images/backgrounds/waves-colors.png);
        height: 185px; }
      .box-cta .box-cta_mobile {
        display: block; }
      .box-cta .container-fluid.flex-row {
        justify-content: center;
        margin-top: -100px;
        position: relative;
        z-index: 10; }
      .box-cta .box-cta_inner {
        max-width: 700px; }
        .box-cta .box-cta_inner .btn,
        .box-cta .box-cta_inner .box-downapp {
          display: none; }
      .box-cta .box-downapp {
        text-align: center;
        justify-content: center; }
      .box-cta .box-cta_form .box-downapp,
      .box-cta .box-cta_form form {
        max-width: 700px; }
      .box-cta .box-cta_form form {
        margin-left: 0;
        padding: 0;
        background-color: transparent; }
      .box-cta .box-cta_form .box-downapp {
        display: flex; }
      .box-cta .box-cta_form .box-cta_form-title span::before {
        background-color: #F38120; } }
  @media (max-width: 575px) {
    .box-cta .box-cta_title {
      font-size: 46px; }
    .box-cta .box-cta_subtitle {
      font-size: 18px; }
    .box-cta .box-cta_form .box-cta_form-title {
      font-size: 28px;
      margin-bottom: 15px; } }

.box-downapp {
  margin: 60px 0 50px;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .box-downapp .box-downapp_title {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    max-width: 315px; }
  .box-downapp a {
    display: inline-block; }
    .box-downapp a + a {
      margin-left: 7px; }
  @media (max-width: 1250px) {
    .box-downapp {
      margin-top: 30px; }
      .box-downapp .box-downapp_title {
        max-width: 1200px;
        width: 100%;
        margin-bottom: 15px; } }

.consult {
  position: relative;
  margin: -65px 0 0;
  z-index: 5; }
  .consult .consult-content {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start; }
  .consult .box-consult {
    max-width: 640px;
    width: 100%;
    margin-left: 130px; }
    .consult .box-consult .box-downapp {
      margin-bottom: 30px;
      display: flex; }
  .consult .box-downapp {
    display: none; }
  .consult figure {
    margin-left: 210px; }
  .consult .btn-whatsapp {
    display: none; }
  @media (max-width: 1700px) {
    .consult figure {
      margin-left: 50px; } }
  @media (max-width: 1550px) {
    .consult {
      margin-top: -30px; }
      .consult .box-consult {
        margin-left: 0; } }
  @media (max-width: 1400px) {
    .consult .container-fluid.flex-row {
      flex-direction: column;
      align-items: center; }
    .consult .box-consult {
      max-width: max-content;
      order: 2; }
    .consult figure {
      order: 1;
      margin-bottom: 40px; } }
  @media (max-width: 1080px) {
    .consult .box-consult {
      max-width: 700px;
      text-align: center; }
    .consult figure {
      margin-top: -185px; }
    .consult .btn-whatsapp {
      display: block;
      margin-top: 30px;
      border-radius: 100px; }
    .consult .box-downapp {
      order: 3;
      justify-content: center;
      text-align: center;
      margin-bottom: 0; } }

.schedules {
  margin: 80px 0 0;
  text-align: center; }
  .schedules .schedules-title {
    font-family: "Lexend Deca", sans-serif;
    font-size: 26px;
    font-weight: 700;
    color: #627282;
    text-transform: none;
    margin: 0 0 35px; }
  .schedules figure {
    display: none; }
  @media (max-width: 1080px) {
    .schedules {
      position: relative;
      margin-top: 30px;
      padding-top: 90px;
      background-color: #7CBCAD; }
      .schedules figure {
        display: inline-block;
        margin-left: auto;
        margin-right: auto; }
      .schedules::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100px;
        width: 100%;
        background-image: url(../images/backgrounds/waves-schedules.png);
        background-position: center center;
        background-repeat: no-repeat; }
      .schedules .schedules-title {
        color: #fff;
        margin-top: 30px; } }
  @media (max-width: 575px) {
    .schedules .schedules-title {
      font-size: 22px; } }

.single-details::after {
  display: block;
  content: "";
  clear: both; }

.single-details p, .single-details ul, .single-details ol {
  color: #98A1AB;
  font-size: 16px;
  line-height: 1.625em;
  margin-bottom: 25px; }

.single-details h2, .single-details h3, .single-details h4 {
  color: #EA1566;
  margin-bottom: 15px;
  line-height: 1.125em; }

.single-details h2 {
  font-size: 26px; }

.single-details h3 {
  font-size: 22px; }

.single-details h4 {
  font-size: 18px; }

.single-details img {
  margin-bottom: 25px; }

.single-details ul li {
  position: relative;
  display: table;
  padding-left: 20px; }
  .single-details ul li + li {
    margin-top: 6px; }
  .single-details ul li::before {
    position: absolute;
    content: "";
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 18px;
    color: #EA1566;
    top: 2px;
    left: 0; }
  .single-details ul li h3 {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.44444em;
    color: #EA1566;
    text-transform: uppercase;
    margin-bottom: 0; }
  .single-details ul li p {
    margin-bottom: 15px; }

.single-details blockquote {
  position: relative;
  border-left: 5px solid #EA1566;
  background-color: #f1f1f1;
  padding: 20px;
  margin-bottom: 30px; }
  .single-details blockquote p {
    margin-bottom: 0; }
    .single-details blockquote p + p {
      margin-top: 25px; }

@media print {
  .site-header,
  .site-footer,
  .breadcrumbs {
    display: none; }
  .weekly-schedule {
    margin-top: 0; }
    .weekly-schedule .head-sec .head-sec_title {
      font-size: 26px; }
    .weekly-schedule .head-sec .head-sec_subtitle {
      font-size: 16px; }
    .weekly-schedule table tr {
      border-top: 1px solid #e5e5e5; }
    .weekly-schedule table p + p {
      margin-top: 5px;
      padding-top: 5px;
      border-top: 1px solid #e5e5e5; }
    .weekly-schedule .social-share {
      display: none; } }
