.btn-all {
    @include flex;
    &.center {
        justify-content: center;
    }
    &.right {
        justify-content: flex-end;
    }
}

.btn {
    @media(hover: hover) {
        @include transition;
    }
}

.btn-block {
    width: 100%;
}

.btn-whatsapp {
    color: #fff;
    background-color: $whatsapp;
    img {
        margin-right: 13px;
    }
    @media(hover: hover) {
        &:hover {
            color: #fff;
            background-color: $brand-color;
        }
    }
}

.btn-brand {
    font-size: 14px;
    color: #fff;
    background-color: $brand-color;
    @media(hover: hover) {
        &:hover {
            background-color: $brand-color-third;
        }
    }
}