.site-head {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    .logo-client {
        position: relative;
        display: inline-block;
        background-color: #fff;
        padding: 35px 25px;
        border-radius: 0px 0px 10px 10px;
        margin-left: 130px;
    }

    @include breakpoint-max(1550px) {
        .logo-client {
            margin-left: 0;
        }
    }
}