.box-time {
    margin-bottom: 30px;
    .card-time {
        border: solid 1px $text-color-light;
        border-radius: 5px;
        padding: 25px 35px;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        p {
            font-size: 20px;
            color: $text-color-light;
            span {
                display: inline-block;
                margin: 0 12px;
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        @media(hover: hover) {
            @include transition;
            p {
                @include transition;
            }
            &:hover {
                background-color: $brand-color-third;
                border-color: $brand-color-third;
                p {
                    color: #fff;
                }
            }
        }
    }
    @include breakpoint-max(container) {
        width: 50%;
    }
    @include breakpoint-max(1080px) {
        width: 100%;
        .card-time {
            border-color: #fff;
            p {
                color: #fff;
            }
        }
    }
}