.box-cta {
    position: relative;
    padding: 220px 0 120px;
    z-index: 5;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        @include size(100%,200px);
        @include imageBg(
            $image: 'waves.png',
            $pos: top center
        );
        z-index: -1;
    }
    @include imageBg(
        $image: 'slide-pc.jpg',
        $pos: top center,
        $size: cover
    );
    .box-cta_inner {
        max-width: 640px;
        width: 100%;
        margin-left: 130px;
    }
    .box-cta_title {
        font-size: 80px;
        font-weight: weight(bold);
        color: $brand-color;
        text-transform: uppercase;
        line-height: em(75px,80px);
    }
    img + .box-cta_title {
        margin-top: 30px;
    }
    .box-cta_subtitle {
        font-family: $text-font;
        font-size: 30px;
        font-weight: weight(regular);
        text-transform: none;
        letter-spacing: normal;
        color: #fff;
        margin-top: 25px;
    }
    .box-cta_form {
        form {
            background-color: $color-1;
            max-width: 540px;
            border-radius: 20px;
            box-shadow: 0 20px 30px rgba($color-1, 0.05);
            padding: 70px 45px 60px;
            margin: 20px 0 0 30px;
        }
        .box-cta_form-title {
            position: relative;
            font-size: 40px;
            color: #fff;
            line-height: em(90px,80px);
            margin: 0 0 40px;
            z-index: 2;
            span {
                position: relative;
                display: inline-block;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -8px;
                    @include size(calc(100% + 12px),calc(100% - 4px));
                    border-radius: 5px;
                    background-color: $brand-color-third;
                    z-index: -1;
                }
            }
        }
        .box-cta_alert {
            margin: 20px 0 0;
            text-align: center;
            color: #fff;
        }
        .box-downapp {
            display: none;
        }
    }
    .box-cta_mobile {
        display: none;
        position: relative;
        z-index: 5;
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            @include size(100%,255px);
            @include imageBg(
                $image: 'waves-orange.png',
                $pos: left top,
            );
            z-index: 1;
        }
    }
    @include breakpoint-max(1550px) {
        .box-cta_inner {
            margin-left: 0;
        }
    }
    @include breakpoint-max(1250px) {
        .box-cta_inner {
            max-width: 470px;
        }
        .box-cta_title {
            font-size: 60px;
        }
        .box-cta_subtitle {
            font-size: 26px;
        }
    }
    @include breakpoint-max(1080px) {
        &::before {
            background-image: url(../images/backgrounds/waves-colors.png);
            height: 185px;
        }
        background-image: none;
        background-color: #f9ad1b;
        padding-top: 0;
        padding-bottom: 185px;
        .box-cta_mobile {
            display: block;
        }
        .container-fluid.flex-row {
            justify-content: center;
            margin-top: -100px;
            position: relative;
            z-index: 10;
        }
        .box-cta_inner {
            max-width: 700px;
            .btn,
            .box-downapp {
                display: none;
            }
        }
        .box-downapp {
            text-align: center;
            justify-content: center;
        }
        .box-cta_form {
            .box-downapp,
            form {
                max-width: 700px;
            }
            form {
                margin-left: 0;
                padding: 0;
                background-color: transparent;
            }
            .box-downapp {
                display: flex;
            }
            .box-cta_form-title {
                span {
                    &::before {
                        background-color: $brand-color-secondary;
                    }
                }
            }
        }
    }
    @include breakpoint-max(small) {
        .box-cta_title {
            font-size: 46px;
        }
        .box-cta_subtitle {
            font-size: 18px;
        }
        .box-cta_form {
            .box-cta_form-title {
                font-size: 28px;
                margin-bottom: 15px;
            }
        }
    }
}

.box-downapp {
    margin: 60px 0 50px;
    @include flex(
        $align: center,
        $justify: flex-start
    );
    .box-downapp_title {
        font-size: 20px;
        font-weight: weight(bold);
        color: $text-color-dark;
        max-width: 315px;
    }
    a {
        display: inline-block;
        & + a {
            margin-left: 7px;
        }
    }
    @include breakpoint-max(1250px) {
        margin-top: 30px;
        .box-downapp_title {
            max-width: 1200px;
            width: 100%;
            margin-bottom: 15px;
        }
    }
}


.consult {
    position: relative;
    margin: -65px 0 0;
    z-index: 5;
    .consult-content {
        @include flex(
            $align: flex-end,
            $justify: flex-start
        );
    }
    .box-consult {
        max-width: 640px;
        width: 100%;
        margin-left: 130px;
        .box-downapp {
            margin-bottom: 30px;
            display: flex;
        }
    }
    .box-downapp {
        display: none;
    }
    figure {
        margin-left: 210px;
    }
    .btn-whatsapp {
        display: none;
    }
    @include breakpoint-max(1700px) {
        figure {
            margin-left: 50px;
        }
    }
    @include breakpoint-max(1550px) {
        margin-top: -30px;
        .box-consult {
            margin-left: 0;
        }
    }
    @include breakpoint-max(1400px) {
        .container-fluid.flex-row {
            flex-direction: column;
            align-items: center;
        }
        .box-consult {
            max-width: max-content;
            order: 2;
        }
        figure {
            order: 1;
            margin-bottom: 40px;
        }
    }
    @include breakpoint-max(1080px) {
        .box-consult {
            max-width: 700px;
            text-align: center;
        }
        figure {
            margin-top: -185px;
        }
        .btn-whatsapp {
            display: block;
            margin-top: 30px;
            border-radius: 100px;
        }
        .box-downapp {
            order: 3;
            justify-content: center;
            text-align: center;
            margin-bottom: 0;
        }
    }
}


.schedules {
    margin: 80px 0 0;
    text-align: center;
    .schedules-title {
        font-family: $text-font;
        font-size: 26px;
        font-weight: weight(bold);
        color: #627282;
        text-transform: none;
        margin: 0 0 35px;
    }
    figure {
        display: none;
    }
    @include breakpoint-max(1080px) {
        position: relative;
        margin-top: 30px;
        padding-top: 90px;
        background-color: $color-1;
        figure {
            display: inline-block;
            margin-left: auto;
            margin-right: auto;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            @include size(100%,100px);
            @include imageBg(
                $image: 'waves-schedules.png'
            );
        }
        .schedules-title {
            color: #fff;
            margin-top: 30px;
        }
    }
    @include breakpoint-max(small) {
        .schedules-title {
            font-size: 22px;
        }
    }
}