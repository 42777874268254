.single-details {
    @include clearfix;
    p, ul, ol {
        color: $text-color-light;
        font-size: $font-size-default;
        line-height: em(26px, $font-size-default);
        margin-bottom: 25px;
    }
    h2, h3, h4 {
        color: $brand-color;
        margin-bottom: 15px;
        line-height: em(18px, $font-size-default);
    }
    h2 {
        font-size: 26px;
    }
    h3 {
        font-size: 22px;
    }
    h4 {
        font-size: 18px;
    }
    img {
        margin-bottom: 25px;
    }
    ul {
        li {
            & + li {
                margin-top: 6px;
            }
            position: relative;
            display: table;
            padding-left: 20px;
            &::before {
                @include pseudo;
                @include iconeBg(
                    $content: $icon-arrow-right,
                    $fontSize: 18px
                );
                color: $brand-color;
                top: 2px;
                left: 0;
            }
            h3 {
                display: block;
                width: 100%;
                font-size: 18px;
                font-weight: weight(bold);
                line-height: em(26px,18px);
                color: $brand-color;
                text-transform: uppercase;
                margin-bottom: 0;
            }
            p {
                margin-bottom: 15px;
            }
        }
    }
    blockquote {
        position: relative;
        border-left: 5px solid $brand-color;
        background-color: $bg-gray;
        padding: 20px;
        margin-bottom: 30px;
        p {
            margin-bottom: 0;
            & + p {
                margin-top: 25px;
            }
        }
    }
}