.breadcrumbs {
    border-bottom: 1px solid $border-color;
    ol {
        position: relative;
        padding-left: 105px;
        @include flex(
            $align: center,
            $justify: flex-start
        );
        &::before {
            position: absolute;
            top: calc(50% + 1px);
            left: 0;
            transform: translateY(-50%);
            content: attr(data-title);
            font-size: 10px;
            font-weight: weight(regular);
            color: rgba(#000,0.3);
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        li {
            position: relative;
            line-height: 0;
            & + li {
                margin-left: 30px;
                &::before {
                    position: absolute;
                    top: 50%;
                    left: -20px;
                    transform: translateY(-50%);
                    @include iconeBg(
                        $content: $icon-arrow-right,
                        $fontSize: 15px
                    );
                    color: $border-color;
                }
            }
            &:first-child {
                margin-left: 10px;
            }
            a {
                display: inline-block;
                font-size: 10px;
                font-weight: weight(regular);
                color: $text-color-light;
                line-height: 0;
                padding: 20px 0 15px;
            }
        }
    }

    @include breakpoint-max(medium) {
        display: none;
    }
}