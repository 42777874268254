.site-foot {
    padding: 80px 0 20px;
    .foot-logo,
    .foot-copy,
    .foot-social {
        @include flex(
            $align: center,
            $justify: flex-start
        );
    }
    .foot-logo {
        .logo-client {
            margin-left: 130px;
        }
    }
    .foot-copy {
        font-size: 18px;
        .foot-alert {
            max-width: 460px;
            margin-left: 35px;
            & + .foot-cnpj {
                margin-left: 50px;
            }
        }
        .foot-cnpj {
            max-width: 340px;
        }
    }
    .social-media {
        @include flex(
            $align: center,
            $justify: flex-start
        );
        li {
            & + li {
                margin-left: 25px;
            }
            a {
                @include flex(
                    $align: center,
                    $justify: center
                );
                @include size(55px);
                border-radius: 50%;
                @media(hover: hover) {
                    &:hover {
                        animation: flip-vertical-right 0.6s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
                    }
                }
                &.facebook {
                    background-color: $color-2;
                }
                &.instagram {
                    background-color: $brand-color;
                }
                &.youtube {
                    background-color: $brand-color-secondary;
                }
            }
        }
    }
    .foot-nav {
        margin-left: 35px;
        @include flex(
            $align: center,
            $justify: flex-start
        );
        li {
            & + li {
                margin-left: 35px;
            }
            a {
                display: block;
                font-size: 16px;
                font-weight: weight(regular);
                color: $color-2;
                text-decoration: underline;
            }
        }
    }
    @include breakpoint-max(1600px) {
        .foot-logo {
            .logo-client {
                margin-left: 0;
            }
        }
        .foot-copy {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .foot-alert {
                margin-left: 0;
                max-width: max-content;
                & + .foot-cnpj {
                    margin-left: 0;
                    margin-top: 20px;
                }
            }
            .foot-cnpj {
                max-width: max-content;
            }
        }
        .foot-nav {
            margin-left: 0;
        }
    }
    @include breakpoint-max(1080px) {
        padding-top: 40px;
    }
    @include breakpoint-max(large) {
        padding-top: 20px;
        text-align: center;
        .foot-logo,
        .foot-copy,
        .foot-social,
        .foot-nav,
        .grid-2,
        .grid-10 {
            width: 100%;
        }
        .foot-social,
        .foot-nav {
            margin-top: 20px;
        }
        .foot-copy {
            align-items: center;
        }
        .foot-logo,
        .foot-social,
        .foot-nav {
            justify-content: center;
            text-align: center;
        }
        .foot-logo {
            .logo-client {
                margin-left: auto;
            }
        }
    }
}